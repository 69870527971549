import React from "react";
import RichText from "../../RichText/RichText.controller";
import styles from "./BannerDetails.module.css";

const BannerDetails = ({ heading = "", description = {} }) => {
  return (
    <div className={styles.root}>
      <p className={styles.heading}>{heading}</p>
      {description && <RichText content={description} />}
    </div>
  );
};

export default BannerDetails;
